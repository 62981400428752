import { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import "./officeLocation.scss";
interface Props {
  locationName: any;
  modalTitle: any;
}

function OfficeLocations({ locationName, modalTitle }: Props) {
  const [open, setOpen] = useState(false);
  let offices;
  if (locationName !== null) {
    offices = locationName?.split(",");
  } else {
    offices = ["N/A"];
  }
  const handleOffice = (locationName: any) => {
    setOpen(true);
  };
  return (
    <>
      {offices?.[0] !== "N/A" && offices?.length > 1 ? (
        <StyledLocation>
          <span onClick={() => handleOffice(locationName)}>{offices?.[0]}</span>
        </StyledLocation>
      ) : (
        <span>{offices?.[0]}</span>
      )}

      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        width={300}
        bodyStyle={{overflowY: 'scroll', maxHeight: 'calc(70vh - 200px)' }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        {offices?.map((item: any) => {
          return <StyledItem>{item}</StyledItem>;
        })}
      </Modal>
    </>
  );
}

const StyledLocation = styled.div`
  color: #40668c;
  text-decoration: underline;
`;

const StyledItem = styled.div`
  align: "center";
`;

export default OfficeLocations;
