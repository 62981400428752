import { Divider, Form, Modal, Popconfirm, Spin } from "antd";
import { MODULE_KEY, fetchFrameArrangerQty } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import AddFAQty from "./AddFAQty";
import "./../../../styles/vendor.scss";
import { useDispatch } from "react-redux";
import { getFADetail } from "./sagas";

function FrameArrangerQty() {
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [visible, setVisible] = useState(false);
  const [frameDetailForm] = Form.useForm();

  useEffect(() => {
    dispatch(fetchFrameArrangerQty({}));
  }, []);
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setModalData({});
    frameDetailForm.resetFields();
    setVisible(true);
  };
  const editDrawer = (record: any) => {
    let payload;
    payload = record?.FrameArrangerID;
    setVisible(true);
    const res = getFADetail(payload);
    res.then((response: any) => {
      setModalData(response);
    });
  };

  const handleCancel = () => {
    dispatch(fetchFrameArrangerQty({}));
  };

  let filterValues = filterColumns(editDrawer);

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Frame to Collection Mapping</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={addDrawer}
            >
              {" "}
              Add New
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="FrameArrangerID"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1300}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 500,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
          }}
        />{" "}
        <AddFAQty
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
          setConfirmLoading={setConfirmLoading}
          frameDetailForm={frameDetailForm}
        />
      </Spin>
    </>
  );
}
export default FrameArrangerQty;
