import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import {
  PRODUCT_PLANOGRAM_DRAWER_COLUMN,
  PRODUCT_PLANOGRAM_LISTING_COLUMN,
} from "src/util/columns";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined, DragOutlined, EditOutlined } from "@ant-design/icons";
import { useMemo } from "react";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  // Memoize filtered data
  const filteredData = useMemo(() => {
    // Helper function to filter and map data
    const filterAndMap = (sourceData: any[], key: string) => {
      const uniqueValues = new Set(
        sourceData
          .map((row: { [x: string]: any }) => row[key])
          .filter((value: string) => value && value !== "")
      );
      return Array.from(uniqueValues).map((value) => ({
        text: value,
        value,
      }));
    };

    return {
      vendors: filterAndMap(data, "Vendor"),
      collections: filterAndMap(data, "Collection"),
      materials: filterAndMap(data, "Material"),
      frameTypes: filterAndMap(data, "FrameType"),
      genders: filterAndMap(data, "Gender"),
      itemGroups: filterAndMap(data, "ItemGroup"),
      lifeCycleStatuses: filterAndMap(data, "LifeCycleStatus"),
      skuGrades: filterAndMap(data, "SKUGrade"),
      colorFamily: filterAndMap(data, "FrameColorFamily"),
      frameShape: filterAndMap(data, "FrameShape"),
      ageGroup: filterAndMap(data, "AgeGroup"),
    };
  }, [data?.length]);
  // Memoize column configurations
  const makeColumns = useMemo(() => {
    const createColumn = (
      title: string,
      dataIndex: string,
      filters: { text: any; value: any }[] | undefined
    ) => ({
      title,
      dataIndex,
      sorter: (a: { [x: string]: string }, b: { [x: string]: any }) =>
        a[dataIndex]?.localeCompare(b[dataIndex]) || 0,
      sortDirections: ["ascend", "descend"],
      filters,
      onFilter: (value: any, record: { [x: string]: string | any[] }) =>
        record[dataIndex]?.indexOf(value) === 0,
      width: 120,
      align: "center",
    });

    return {
      vendorColumn: createColumn("Vendor", "Vendor", filteredData.vendors),
      collectionColumn: createColumn(
        "Collection",
        "Collection",
        filteredData.collections
      ),
      materialColumn: createColumn(
        "Material",
        "Material",
        filteredData.materials
      ),
      frameTypeColumn: createColumn(
        "Frame Type",
        "FrameType",
        filteredData.frameTypes
      ),
      genderColumn: createColumn("Gender", "Gender", filteredData.genders),
      itemGroupColumn: createColumn(
        "Item Group",
        "ItemGroup",
        filteredData.itemGroups
      ),
      lifeCycleStatusColumn: createColumn(
        "Life Cycle Status",
        "LifeCycleStatus",
        filteredData.lifeCycleStatuses
      ),
      skuGradeColumn: createColumn(
        "SKU Grade",
        "SKUGrade",
        filteredData.skuGrades
      ),
      frameColorFamilyColumn: createColumn(
        "Frame Color Family",
        "FrameColorFamily",
        filteredData.colorFamily
      ),
      frameShapeColumn: createColumn(
        "Frame Shape",
        "FrameShape",
        filteredData.frameShape
      ),
      ageGroupColumn: createColumn(
        "Age Group",
        "AgeGroup",
        filteredData.ageGroup
      ),
    };
  }, [filteredData]);

  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    width: 100,
    fixed: "right",
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            style={{ marginRight: "10px" }}
            icon={<EditOutlined />}
            children={undefined}
            onClick={() => editDrawer(record.ID1)}
          ></Button>
          <Button
            style={{ marginLeft: "20px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };

  let columns = insertAt(
    PRODUCT_PLANOGRAM_LISTING_COLUMN,
    1,
    0,
    makeColumns.vendorColumn
  );
  columns = insertAt(columns, 2, 0, makeColumns.collectionColumn);
  columns = insertAt(columns, 10, 0, makeColumns.frameTypeColumn);
  columns = insertAt(columns, 11, 0, makeColumns.genderColumn);
  columns = insertAt(columns, 12, 0, makeColumns.materialColumn);
  columns = insertAt(columns, 14, 0, makeColumns.itemGroupColumn);
  columns = insertAt(columns, 18, 0, actionColumn);
  let drawerColumns = [
    ...columns.filter((col: any) => col.dataIndex !== "operation"),
    ...PRODUCT_PLANOGRAM_DRAWER_COLUMN,
  ];
  drawerColumns = insertAt(
    drawerColumns,
    36,
    0,
    makeColumns.frameColorFamilyColumn
  );
  drawerColumns = insertAt(drawerColumns, 37, 0, makeColumns.frameShapeColumn);
  drawerColumns = insertAt(drawerColumns, 38, 0, makeColumns.ageGroupColumn);
  drawerColumns = insertAt(
    drawerColumns,
    42,
    0,
    makeColumns.lifeCycleStatusColumn
  );
  drawerColumns = insertAt(drawerColumns, 44, 0, makeColumns.skuGradeColumn);
  return { columns, data, drawerColumns };
}
