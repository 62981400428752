import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Select,
  Checkbox,
  Descriptions,
  DatePicker,
  DatePickerProps,
  Radio,
} from "antd";
import { useEffect, useState } from "react";
import {
  getCollectionType,
  getFrameCollection,
  saveOfficeCollectionMapping,
  updateOfficeCollectionMapping,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import moment from "moment";
import { getOffices } from "src/components/systemPreference/sagas";
import { DATE_FORMAT } from "src/util/columns";
import { StatusValue } from "src/util/utils";

interface AddOCMappingType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
  frameCollection: any;
}

function AddOCMapping({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
  frameCollection
}: AddOCMappingType) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [office, setOffice] = useState([]);
  const [collectionType, setCollectionType] = useState([]);
  const [FrameArrangerID, setFrameArrangerId] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [regionValue, setRegionValue] = useState([]);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [updatedDate, setUpdatedDate] = useState<any>(memoizeDate);

  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        FrameArrangerID: data?.FrameArrangerID,
        CollectionType: data?.CollectionType,
        Offices: data?.LocationName + " (" + data?.OfficeNum + ")",
        Notes: data?.UpdateNotes,
        UpdatedDate: data.UpdatedDate !== null ? moment(data.UpdatedDate) : "",
      };
      frameDetailForm.setFieldsValue(editFieldObj);
      setUpdatedDate(data.UpdatedDate !== null ? moment(data.UpdatedDate) : "");
    }
  }, [data]);

  useEffect(() => {
    if (frameCollection) {
      setFrameArrangerId(frameCollection);
    }
  }, [frameCollection]);

  useEffect(() => {
    const location: any = [];
    const region: any = [];
    Promise.all([
      getFrameCollection(),
      getCollectionType(),
      getOffices({}),
    ]).then((res) => {
      
      const sortedFAId: any = res[0]?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });

      const sortedFC: any = frameCollection?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });

      setFrameArrangerId(Object.keys(data).length ? sortedFC : sortedFAId);
      const sortedCollectionType: any = res[1]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setCollectionType(sortedCollectionType);
      const sortedOffice: any = res[2]?.sort((a: any, b: any) => {
        return (
          a.LocationName !== null &&
          a.LocationName.localeCompare(b.LocationName, "sv")
        );
      });
      setOffice(sortedOffice);
      sortedOffice.map((item: any) => {
        location.push({
          LocationNumber: item?.LocationNumber,
          LocationName: item?.LocationName,
        });
        if (!region.includes(item.Region)) {
          region.push(item.Region);
        }
      });
      setLocationData(location);
      setRegionData(region);
    });
  }, []);

  const handleClose = () => {
    setData([]);
    setVisibleStatus(false);
    setRegionData([]);
    setDistrictData([]);
    frameDetailForm.resetFields();
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let payload: any = {
        FrameArrangerID: fields?.FrameArrangerID,
        CollectionType: fields?.CollectionType,
        UpdateNotes: fields?.Notes,
        UpdatedDate: updatedDate !== "Invalid date" ? updatedDate : "",
      };
      if (Object.keys(data).length) {
        payload = {
          ...payload,
          ID: data?.ID,
          officeLocations: [
            {
              LocationName: data?.LocationName,
              OfficeNum: data?.OfficeNum,
            },
          ],
        };
        res = await updateOfficeCollectionMapping(payload);
      } else {
        let officeLocations: any = [];
        if (fields.Offices.includes("All")) {
          locationData.map((ele: any) => {
            officeLocations.push({
              LocationName: ele.LocationName,
              OfficeNum: ele.LocationNumber,
            });
          });
        } else {
          office.filter((ele: any, ind: any) => {
            fields?.Offices.some((elem: any) => {
              const officeNumber = elem.split("(")[1].split(")")[0];
              if (officeNumber === ele.LocationNumber) {
                officeLocations.push({
                  LocationName: ele.LocationName,
                  OfficeNum: ele.LocationNumber,
                });
              }
            });
          });
        }
        payload = {
          ...payload,
          officeLocations: officeLocations,
        };
        res = await saveOfficeCollectionMapping(payload);
      }
      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };
  const handleAllData = async (key: any, value: any) => {
    switch (key) {
      case "region":
        const districtLocation: any = [];
        const district: any = [];
        if (value.length > 0) {
          office?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.Region) {
                if (!district.includes(item.District)) {
                  district.push(item.District);
                }
                districtLocation.push({
                  LocationNumber: item?.LocationNumber,
                  LocationName: item?.LocationName,
                });
              }
            });
          });
          setRegionValue(value);
          const districtData = district?.sort((a: any, b: any) => {
            return a.localeCompare(b, "en", { numeric: true });
          });
          setDistrictData(districtData);
          setLocationData(districtLocation);
        } else {
          frameDetailForm.setFieldsValue({ District: [] });
          setDistrictData([]);
          const loc: any = office?.map((item: any) => item.LocationNumber);
          setLocationData(loc);
        }
        break;
      case "district":
        const location: any = [];
        frameDetailForm.resetFields(["Offices"]);
        if (value.length > 0) {
          office?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.District) {
                if (!location.includes(item.LocationNumber)) {
                  location.push({
                    LocationNumber: item?.LocationNumber,
                    LocationName: item?.LocationName,
                  });
                }
              }
            });
          });
          setLocationData(location);
        } else {
          office?.map((item: any) => {
            regionValue?.map((ele: any) => {
              if (ele === item.Region) {
                location.push({
                  LocationNumber: item?.LocationNumber,
                  LocationName: item?.LocationName,
                });
              }
            });
          });
          setLocationData(location);
        }
        break;
      default:
      // code block
    }
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const drawerTitle = Object.keys(data).length
    ? "Edit Office Collection Mapping"
    : "Add Office Collection Mapping";

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Frame Arranger ID"
                  name="FrameArrangerID"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select Frame Arranger ID",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Frame Arranger ID"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                  >
                    {FrameArrangerID?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item?.FrameArrangerID}>
                          {item?.Vendor}-{item?.Collection}-
                          {item?.FrameTypeDescription} ({item?.FrameArrangerID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Collection Type"
                  name="CollectionType"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please select vendor" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Collection Type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collectionType?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.Description}>
                          {item?.Description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              {!Object.keys(data).length && (
                <div>
                  <Form.Item
                    label="Region"
                    name="Region"
                    className="label-text region-field"
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select Region"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      style={{ width: "100%" }}
                      onChange={(value) => handleAllData("region", value)}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      {regionData?.map((item: any, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="District"
                    name="District"
                    className="label-text"
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select District"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      style={{ width: "100%" }}
                      onChange={(value) => handleAllData("district", value)}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      maxTagCount="responsive"
                      disabled={!districtData.length}
                    >
                      {districtData?.map((item: any, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              <div>
                <Form.Item
                  label="Offices"
                  name="Offices"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one office",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    allowClear={true}
                    mode="multiple"
                    disabled={!!Object.keys(data).length}
                    placeholder="Select Offices"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    optionLabelProp="label"
                    maxTagCount="responsive"
                  >
                    <Option value="All">Select All</Option>
                    {locationData?.map((item: any, index) => {
                      return (
                        <Option
                          key={index}
                          value={`${item.LocationName} (${item.LocationNumber})`}
                        >
                          {item.LocationName} ({item.LocationNumber})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Changed Date"
                  name="UpdatedDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="UpdatedDate"
                    value={updatedDate && moment(updatedDate)}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setUpdatedDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Changed Notes"
                  name="Notes"
                  className="label-text region-field"
                >
                  <Input name="Notes" />
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Drawer>
    </>
  );
}

export default AddOCMapping;
