import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Spin,
  Select,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { fetchVendorList } from "src/components/vendor/vendor-sagas";
import {
  getDatabaseCollection,
  getFrameType,
  saveFrameArrangerQty,
  updateFrameArrangerQty,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { DATE_FORMAT } from "src/util/columns";
import moment from "moment";

interface AddFAQtyType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
  setConfirmLoading: any;
  frameDetailForm: any;
}

function AddFAQty({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
  setConfirmLoading,
  frameDetailForm,
}: AddFAQtyType) {
  const { Option } = Select;
  const [vendors, setVendors] = useState([]);
  const [frameType, setFrameType] = useState([]);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [dateAdded, setDateAdded] = useState<any>(memoizeDate);

  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        FrameArrangerID: data?.FrameArrangerID,
        FrameArrangerQuantity: data?.ArrangerQty,
        FrameTypeID: data?.FrameTypeID,
        FrameTypeDescription: data?.FrameTypeDescription,
        CountperCollection: data?.CountperCollection,
        SunglassQuantity: data?.SunQty,
        Active: data?.Active,
        DatabaseCollectionID:
          data.Vendor +
          "-" +
          data.Collection +
          " (" +
          data?.DatabaseCollection +
          ")",
        DateAdded: moment(data.DateAdded),
      };
      frameDetailForm.setFieldsValue(editFieldObj);
    }
  }, [data]);
  useEffect(() => {
    Promise.all([getDatabaseCollection(), getFrameType({})]).then((res) => {
      const sortedVendors: any = res[0]?.sort((a: any, b: any) => {
        if (a.VendorName !== null) {
          return a.VendorName.localeCompare(b.VendorName);
        }
      });
      setVendors(sortedVendors);
      const sortedFrameType: any = res[1]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setFrameType(sortedFrameType);
    });
  }, []);
  const handleClose = () => {
    frameDetailForm.resetFields();
    setData({});
    setVisibleStatus(false);
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let payload: any = {
        ArrangerQty: fields.FrameArrangerQuantity,
        FrameTypeID: fields.FrameTypeID,
        CountperCollection: fields.CountperCollection,
        SunQty: fields.SunglassQuantity,
        Active: fields.Active,
        DatabaseCollection: Object.keys(data).length
          ? data.DatabaseCollection
          : fields.DatabaseCollectionID,
        DateAdded: dateAdded !== "Invalid date" ? dateAdded : moment().format(DATE_FORMAT),
      };
      setConfirmLoading(true);
      if (Object.keys(data).length) {
        payload = { ...payload, FrameArrangerID: data?.FrameArrangerID };
        res = await updateFrameArrangerQty(payload);
      } else {
        res = await saveFrameArrangerQty(payload);
      }
      if (res) {
        onLoad();
        showSuccessNotification(res);
        handleClose();
      } else {
        showErrorNotification(res);
      }
      setConfirmLoading(false);
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const drawerTitle = Object.keys(data).length
    ? "Edit Frame Collection Mapping"
    : "Add Frame Collection Mapping";
  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Database Collection ID"
                  name="DatabaseCollectionID"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select database collection id",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Database Collection ID"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                  >
                    {vendors?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.DatabaseCollectionID}>
                          {item?.VendorName} - {item?.CollectionName} (
                          {item?.DatabaseCollectionID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Type"
                  name="FrameTypeID"
                  className="label-text region-field"
                  rules={[
                    { required: true, message: "Please select frame type" },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Frame Type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {frameType?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item?.ID}>
                          {item?.Description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Frame Arranger Quantity"
                  name="FrameArrangerQuantity"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Frame Arranger Quantity",
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Frame Arranger Quantity should be numeric`,
                    },
                    () => ({
                      validator(rule, value) {
                        if (
                          parseInt(value) < 0 || parseInt(value) > 10000
                        ) {
                          return Promise.reject(
                            "Please enter value between 0 to 10000"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input name="FrameArrangerQuantity" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Count per Collection"
                  name="CountperCollection"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Count per Collection",
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Count per Collection should be numeric`,
                    },
                    () => ({
                      validator(rule, value) {
                        if (
                          parseInt(value) == 0 || parseInt(value) > 10000
                        ) {
                          return Promise.reject(
                            "Please enter value between 1 to 10000"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input name="CountperCollection" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Sunglass Quantity"
                  name="SunglassQuantity"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Sunglass Quantity should be numeric`,
                    },
                    () => ({
                      validator(rule, value) {
                        if (
                          parseInt(value) > 0 || value == null || value == '' || Number.isNaN(parseInt(value))
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please enter value greater than 0"
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input name="SunglassQuantity" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="Active"
                  className="label-text region-field"
                >
                  <Checkbox name="Active" value={true}>
                    {" "}
                    Active{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Date Added"
                  name="DateAdded"
                  className="label-text region-field"
                >
                  <DatePicker
                    disabled={!!Object.keys(data).length}
                    format={DATE_FORMAT}
                    name="DateAdded"
                    value={dateAdded && moment(dateAdded)}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setDateAdded(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Drawer>
    </>
  );
}

export default AddFAQty;
