import React, { useState, useRef, useEffect } from "react";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { Button, Form, Input, InputRef, Pagination, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useTableProps from "../../../hooks/table/useTableProps";
import styles from "./GenericTable.module.scss";
import EditableCell, { EditableRow } from "./Columns/EditableCell";
import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "src/util/columns";
import { number } from "yargs";

interface GenericTable {
  columns: any;
  dataSource?: any;
  onRowSelect?: any;
  selected?: any;
  rowkey: any;
  expandable?: any;
  moduleKey?: any;
  rowSelection?: any;
  loading?: any;
  xScroll?: any;
  yScroll?: any;
  hidePagination?: any;
  onSaveChanges?: any;
  pagination?: any;
  hideTotal?: any;
  style?: any;
  form?: any;
  isReset?: any;
  rowClassName?: any;
  dateFormat?: any;
  filteredData?: any;
  setFilteredData?: any;
}

function GenericTable({
  columns,
  onRowSelect = () => {},
  selected = {},
  rowkey = "",
  expandable,
  moduleKey,
  rowSelection,
  xScroll,
  yScroll,
  dataSource,
  hidePagination = false,
  pagination = false,
  hideTotal = false,
  onSaveChanges,
  style,
  form,
  isReset,
  rowClassName = "",
  dateFormat,
  filteredData,
  setFilteredData,
}: GenericTable) {
  const [searchText, setSearchText] = useState("");
  const [index, setIndex] = useState(0);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [total, setTotal] = useState(0);
  // const [form] = Form.useForm();
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0] || "");
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    handleReset();
  }, [isReset]);
  const handleReset = () => {
    setIndex(index + 1);
    filteredData && setFilteredData([]);
  };

  const checkDateFormat = (text: any, dataIndex: any) => {
    const formats = [moment.ISO_8601, "MM/DD/YYYY  :)  HH*mm*ss"];
    const res = moment(text, formats, true).isValid();
    if (res && typeof text !== "number") {
      if (dateFormat) {
        return moment(text).format(DATE_TIME_FORMAT);
      } else {
        if (!/^\d+$/.test(text)) {
          return moment(text).format(DATE_FORMAT);
        } else {
          return text ? text.toString() : "N/A";
        }
      }
    } else if (typeof text === "number" || typeof text === "boolean") {
      return text.toString();
    } else {
      return text ? text.toString() : "N/A";
    }
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] !== null &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={checkDateFormat(text, dataIndex)}
        />
      ) : (
        checkDateFormat(text, dataIndex)
      ),
  });

  const filterColumn = columns.map((column: any) => {
    if (column.filterMultiple) {
      return { ...column, ...getColumnSearchProps(column.dataIndex) };
    } else if (column.filtered) {
      return {
        ...column,
        onCell: (record: any, index: any) => ({
          inputType: column.dataIndex,
          dataIndex: column.dataIndex,
          title: column.title,
          editable: column.filtered,
          onSaveChanges: onSaveChanges,
          record,
          index,
        }),
      };
    } else {
      return { ...column };
    }
  });

  const tableProps = useTableProps(
    moduleKey,
    [rowSelection],
    rowkey,
    filterColumn,
    dataSource
  );

  useEffect(() => {
    setTotal(tableProps.dataSource.length);
  }, [tableProps.dataSource]);

  const onShowSizeChange = (current: any, pageSize: any) => {
    console.log(current, pageSize);
  };
  const handleChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { action: string; currentDataSource: any }
  ) => {
    setTotal(extra.currentDataSource?.length);
    if (
      (extra.action === "filter" || extra.action === "sort") &&
      filteredData
    ) {
      if (extra.currentDataSource?.length > 0) {
        setFilteredData(extra.currentDataSource);
      } else {
        setFilteredData(["none"]);
      }
    }
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  return (
    <>
      <div className={styles["table-container"]}>
        <div className={styles["table-wrapper"]}>
          <Table
            key={index}
            className={styles["table-common"]}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  onRowSelect(record, rowIndex);
                },
              };
            }}
            rowClassName={(record: any) => {
              if (
                selected &&
                rowkey &&
                selected[rowkey] === record[rowkey] &&
                rowkey !== "OrderNumber"
              ) {
                return "selected-background";
              } else {
                if (
                  rowClassName &&
                  record?.PreviousReceived !== record?.Quantity
                ) {
                  return "editable-row colored-data-row";
                } else {
                  return "editable-row";
                }
              }
              return "";
            }}
            components={components}
            {...tableProps}
            rowSelection={rowSelection}
            expandable={expandable}
            scroll={{ x: xScroll, y: yScroll }}
            style={style}
            pagination={
              pagination
                ? {
                    showTotal: (total, range) => {
                      setTotal(total);
                    },
                    defaultPageSize: pagination.defaultPageSize,
                    showSizeChanger: true,
                    pageSizeOptions: pagination.pageSizeOptions,
                  }
                : false
            }
            onChange={handleChange}
          />
        </div>

        <div className={styles["pagination-container"]}>
          {hidePagination ? (
            ""
          ) : (
            <>
              <div className={styles["pagination-actions-wrapper"]}>
                {!hideTotal && (
                  <div className={styles["total-container"]}>
                    <div className={styles["total-title"]}>TOTAL RECORDS</div>
                    <div className={styles["total-count"]}>
                      <span>{total}</span>
                    </div>
                  </div>
                )}
                {/* <Pagination
                                    defaultCurrent={0}
                                    onShowSizeChange={onShowSizeChange}
                                    pageSizeOptions={['10', '25', '50', '100']}
                                    showSizeChanger={true}
                                    total={total}
                                    hideOnSinglePage={true} /> */}
              </div>
              <img
                alt="logo"
                className={styles["logo"]}
                src={"./images/logo.svg"}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default GenericTable;
